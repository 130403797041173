import classes from './ColorSchemeSwitch.module.css';

import type { SwitchProps } from '@mantine/core';
import { Switch } from '@mantine/core';
import { useHotkeys } from '@mantine/hooks';
import { IconMoonStars, IconSun } from '@tabler/icons-react';

import { useFetcher, useRouteData } from '~/features/serialization';
import type { loader } from '~/root';

interface ColorSchemeSwitchProps extends SwitchProps {}

export function ColorSchemeSwitch({ ...others }: ColorSchemeSwitchProps) {
  const { colorScheme } = useRouteData<typeof loader>('root');
  const colorSchemeFetcher = useFetcher();

  const setColorScheme = (scheme: 'light' | 'dark') => {
    colorSchemeFetcher.submit({ 'color-scheme': scheme }, { action: '/action/color-scheme', method: 'POST' });
  };

  useHotkeys([['mod+J', () => setColorScheme(colorScheme === 'dark' ? 'light' : 'dark')]]);

  return (
    <Switch
      size="md"
      color="gray.3"
      radius={0}
      checked={colorScheme === 'light'}
      onLabel={<IconSun size="1rem" stroke={2.5} className={classes['switch-on']} />}
      offLabel={<IconMoonStars size="1rem" stroke={2.5} className={classes['switch-off']} />}
      onChange={(e) => setColorScheme(e.currentTarget.checked ? 'light' : 'dark')}
      {...others} />);


}